import React, { useEffect } from 'react'
import '../../scss/main.scss'
import { useDispatch } from 'react-redux';
import * as accessibility from '../../helpers/accessibility-helper';
import * as firebaseStartup from '../../helpers/startup-helper';

let startupTasks = false;

export default function Layout({ children, transitionStatus, extraClass }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!startupTasks) {
      accessibility.startupTasks(dispatch);
      firebaseStartup.startupTasks(dispatch);
      startupTasks = true;
    }
  });

  return (
    <main className={extraClass + ' ' + transitionStatus}>
      {children}
    </main>
  )
}