import firebase from "gatsby-plugin-firebase";
import * as siteActions from '../state/actions/actions-site';
import * as chatActions from '../state/actions/actions-chatroom';
import { store } from './../state/reduxWrapper';

const db = firebase.firestore();
const rtdb = firebase.database();
const getSiteState = () => store.getState().siteState;

export async function startupTasks(dispatch) {  
  const state = getSiteState();
  
  if (state.firebaseStartup == false) {
    firebase.analytics();
    firebase.performance();
    await getCounts(dispatch);

    let { uid } = await getUID(dispatch);
    await getUser(dispatch, uid);
    dispatch(siteActions.setFirebaseStartup(true))
  }
}

export async function getUID(dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      await firebase.auth().signInAnonymously()
      // TODO: user account setup change
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          dispatch(siteActions.setFirebaseUid(user.uid))
          let realtimeUsers = rtdb.ref(`users`).push();
          realtimeUsers.set({online: true});
          rtdb.ref(`users/${realtimeUsers.key}`).onDisconnect().update({online: false});

          // Update redux
          dispatch(siteActions.setRealtimeKey(realtimeUsers.key));
          resolve(user)
        } else {
          resolve(null)
        }
      });
    } catch (e) {
      resolve(null)
    }
  });
}

export async function getCounts(dispatch) {
  return new Promise(async (resolve, reject) => {
    try {
      db.collection("counts").doc(`site-counts`).onSnapshot(async doc => {
        let countsData = doc.data();
        dispatch(siteActions.setFirebaseCounts(countsData))
      });
      resolve(true)
    } catch (e) {
      resolve(null)
    }
  });
}

export async function getUser(dispatch, uid) {
  return new Promise(async (resolve, reject) => {
    try {
      db.collection("users").doc(uid).onSnapshot(async doc => {
        let userData = doc.data();
        dispatch(siteActions.setUserObject(userData === undefined ? null : userData))
      });
      resolve(true)
    } catch (e) {
      resolve(null)
    }
  });
}