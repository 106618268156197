// ACTIONS
export const setInitialState = () => ({ type: 'SET_INITIAL_STATE' });
export const setRoomStep = roomStep => ({ type: 'SET_ROOM_STEP', data: roomStep });
export const setUserType = userType => ({ type: 'SET_USER_TYPE', data: userType });
export const setRoomID = roomID => ({ type: 'SET_ROOM_ID', data: roomID });

export const setFavico = favico => ({ type: 'SET_FAVICO', data: favico });
export const setFavicoCount = favicoCount => ({ type: 'SET_FAVICO_COUNT', data: favicoCount });
export const setUserWaiting = userWaiting => ({ type: 'SET_USER_WAITING', data: userWaiting });

export const setSubscriptions = subscription => ({ type: 'SET_SUBSCRIPTIONS', data: subscription });
export const setRoomData = roomData => ({ type: 'SET_ROOM_DATA', data: roomData });
export const setChatRows = chatRows => ({ type: 'SET_CHATROWS', data: chatRows });
export const setChatRowData = chatRowData => ({ type: 'SET_CHATROW_DATA', data: chatRowData });