import {isMobile, isTablet} from 'react-device-detect';
import { detect } from 'detect-browser';
import * as actions from '../state/actions/actions-site';
import objectFitImages from 'object-fit-images';

export function startupTasks(dispatch) {  
  if (!isMobile && !isTablet && !window.keyboardSet) {
    keyboardNavigation();
    resetKBAccessibility();
  } 
  browserDetect(dispatch);
}

export function keyboardNavigation() {
  window.keyboardSet = true;
  keyboardNavigation = null;
  document.addEventListener("keydown", (e) => {
    
    e = e || window.event;
    switch (e.keyCode) {
      case 9: 
        let body = document.body;
        body.classList.add("kb-accessibility");
        break;
      default:
        break;
    }
  }, false);
}

export function resetKBAccessibility() {
  document.addEventListener("mousedown", (e) => {
    let body = document.body;
    body.classList.remove("kb-accessibility")
  }, false);
}

export function browserDetect(dispatch) {
  const browser = detect();
  let gatsbyEl = document.querySelector("#___gatsby");
  gatsbyEl.classList.add(browser.name);
  dispatch(actions.setBrowser(browser.name))
}
