import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link';
import firebase from "gatsby-plugin-firebase";

export default function AnimatedLink(props) {
  let { logEvent, onClick, delay, state, className, to, children } = props;
  let entry = {delay: delay !== undefined ? delay : 0.4, length:0}
  let finalEntry = {};

  if (state !== undefined) {
    finalEntry = Object.assign(entry, state)
  } else {
    finalEntry = entry;
  }

  function checkOnClick() {
    if (logEvent !== undefined) firebase.analytics().logEvent(logEvent)
    if (onClick !== undefined) onClick()
  }

  return (
    <TransitionLink
      to={to}
      className={className !== undefined ? className : ''}
      onClick={checkOnClick}
      preventScrollJump
      trigger={() => setTimeout(() => window.scrollTo(0,0), 400)}
      entry={finalEntry}
      exit={{
        length: 0.4
      }}
    >
      {children}
    </TransitionLink>
  )
}